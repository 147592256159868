import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, USER_BUSINESS_LINE} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  businessLineDetails: {
    default: localStorage.getItem("currentBusinessLine") === null ? 'NBS-VB' : localStorage.getItem("currentBusinessLine"),
    show_dropdown: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case INIT_URL: {
      return {...state, initURL: action.payload};
}


    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
        projectTypeDetails: null
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_BUSINESS_LINE: {
      return {
        ...state,
        businessLineDetails: action.payload,
      };
    } // User business line reducer added July 27 2020

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
}
