import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
// Add this import:
import {AppContainer} from 'react-hot-loader';
import 'rsuite/dist/styles/rsuite.min.css';

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
// registerServiceWorker();
unregisterServiceWorker(); // Added to disable cache - on dec 7 2019

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
