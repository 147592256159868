import React from "react";
import {Button, Form, Input, message} from "antd";
import {Link} from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import axios from 'util/Api';

const FormItem = Form.Item;

class ForgotPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values)
        const AuthStr = axios.defaults.headers.common['Authorization']; 
        axios.post('api/user/forgotpassword', values, { 'headers': { 'Authorization': AuthStr } }
        ).then(({data}) => {   
          message.success(data.message, 3);
          this.props.history.push('/signin');
        }).catch((error) => {
          console.log(error.message)
          message.error(error.message ? error.message + "!! Contact Administrator" : "Some fatal error has occured!! Contact Administrator", 10); 
          //this.props.userSignOut();   // If a failure in API such as 401 / 404 etc. the app will signout.
        })
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <img alt="Kone Logo" className="kone-logo" src={require("assets/images/Kone-Logo.svg")} width="120px"/>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo-wid">
                <h1 style={{fontSize:'26px'}}><IntlMessages id="app.userAuth.forgotPassword"/></h1>
                <p><IntlMessages id="app.userAuth.forgot"/></p>
                <p></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/Kone-Logo.svg")} width="76px"/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('contact_email', {
                    //initialValue: "demo@example.com",
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.resetPassword"/>
                  </Button>
                </FormItem>
                <FormItem>
                  <Link to="/signin"> Back to 
                    <IntlMessages
                    id="app.userAuth.signIn"/></Link>
                </FormItem>
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedNormalForgotPasswordForm;